.invoice-modal {
  margin-bottom: 20px;
  .view-invoice {
    cursor: pointer;
    color: $color-bamboo-light;
  }
}
.ReactModal__Overlay {
  overflow-y: auto;
}

.ReactModalPortal {
  padding-bottom: 1.25rem;
  z-index: 2;
  position: absolute;
  .ReactModal__Content {
    width: 100%;

    @media (max-width: $screen-960) {
      canvas {
        width: auto;
        height: 80vh;
      }
    }

    @media (min-width: $screen-960) {
      width: auto;
    }
  }

  .modal__header {
    .modal__button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .modal-content {
    flex-direction: column-reverse;
  }
  .modal-footer {
    justify-content: flex-start;
  }

  .modal-body {
    embed {
      min-width: 100%;
      height: 800px;
      @media (max-width: $screen-960) {
        height: 400px;
      }
    }

  }

  .react-pdf__Document {
    // max-height: 400px;
    overflow: auto;
    border: 1px solid lightgray;
    // margin-top: 30px;
  }

  .modal-footer {
    margin-top: 20px;
    position: initial;
    a {
      color: $color-bamboo-light;
      cursor: pointer;

      &.pdf-download {
        margin-right: 20px;
      }
    }
  }
}
